<!--Display last 200 articles-->
<div class="administrator-articles">
  <div class="page-title">
    <h1>Članci</h1>
  </div>
  <div class="top">
    <div class="filters">
      <button (click)="getActive('active')"
              [disabled]="selectedFilter == 'active'">
        Aktivni
      </button>
      <button (click)="getDelete('inactive')"
              [disabled]="selectedFilter == 'inactive'">
        Uklonjeni
      </button>
    </div>
  </div>
  <div class="list" *ngIf="this.displayArticles" (window:scroll)="onScroll()">
    <div class="articles" *ngFor="let article of displayArticles">
      <img src="{{ article.image_list?.[0]?.url }}">
      <p>{{article.title}}</p>
      <div class="overlay">
        <button [routerLink]="'/chair/edit/' + article[ArticlesProperty.id]" class="edit-button">
          <i class="fa fa-pencil"></i>
        </button>
        <button class="remove-button" (click)="deleteArticle(article)">
          <i class="fa fa-remove"></i>
        </button>
      </div>
    </div>
  </div>
</div>
