export enum ChairProperty {
  id = '_id',
  password = 'password',
  name = 'name',
  initials = 'initials',
  email = 'email',

  isKing = 'isKing',
  isAdmin = 'isAdmin',
  isEditor = 'isEditor',

  permissionArticles = 'permissionArticles',
  permissionCategories = 'permissionCategories',
  permissionTags = 'permissionTags',
  permissionShows = 'permissionShows',
  permissionSurveys = 'permissionSurveys',
  accessAllSignatures = 'accessAllSignatures',
  sendNotification = 'sendNotification',

  permissionCreateChairs = 'permissionCreateChairs',
}
