<div class="home">
<!--  <app-home-tags></app-home-tags>-->
  <app-highlight></app-highlight>
  @for (article of articles; let i = $index; track article){
    <ng-container *ngIf="i % 3 === 0 && article.length > 0">
      <app-home-category-one [articles]="article" [category]="categories[i]" [categoryNo]="i"></app-home-category-one>
    </ng-container>
    <ng-container *ngIf="i % 3 === 1 && article.length > 0">
      <app-home-category-two [articles]="article" [category]="categories[i]" [categoryNo]="i"></app-home-category-two>
    </ng-container>
    <ng-container *ngIf="i % 3 === 2 && article.length > 0">
      <app-home-category-three [articles]="article" [category]="categories[i]" [categoryNo]="i"></app-home-category-three>
    </ng-container>
  }

<!--  <div id="hayat_ba_1000x100_anchor_responsive">-->
<!--    <script>-->
<!--      googletag.cmd.push(function() { googletag.display('hayat_ba_1000x100_anchor_responsive'); });-->
<!--    </script>-->
<!--  </div>-->
</div>
