import {Component} from '@angular/core';
import {Articles} from "../../../models/articles.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {ArticlesService} from "../../../services/articles.service";
import {ArticlesProperty} from "../../../models/articles-property.enum";
import {
  SurveyConfirmationDialogComponent
} from "../../survey/survey-confirmation-dialog/survey-confirmation-dialog.component";
import {ConfirmationDialogComponent} from "../../dialogs/confirmation-dialog/confirmation-dialog.component";
import {RouterLink} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-administrator-articles',
  templateUrl: './administrator-articles.component.html',
  standalone: true,
    imports: [
        RouterLink,
        NgForOf,
        NgIf,
        FormsModule
    ],
  styleUrls: ['./administrator-articles.component.css']
})
export class AdministratorArticlesComponent {
  articles!: Articles[];
  deletedArticles!: Articles[];

  displayArticles!: Articles[];

  private page = 1;
  private articlesPerPage = 15;
  private newArticlesLoading: boolean = false
  private noMoreArticles: boolean = false

  selectedFilter: string = 'active';

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private articlesService: ArticlesService,
  ) {
  }
  ngOnInit(): void {
    this.articlesService.getAllArticlesByDate(1).subscribe(
      (articles: Articles[]) => {
        this.articles = articles; // Assign the retrieved courses to the courses property
        this.displayArticles = articles; // Assign the retrieved courses to the courses property
        if(articles.length < this.articlesPerPage && this.selectedFilter === 'active')
        {
          this.noMoreArticles = true;
        }
      },
      (error) => {
        this.snackBar.open(error, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });
        console.error('Error getting editors:', error);
      }
    );
    this.articlesService.getDeletedArticles(1).subscribe(
      (articles: Articles[]) =>{
        this.deletedArticles = articles; // Assign the retrieved courses to the courses property
        if(articles.length < this.articlesPerPage && this.selectedFilter === 'inactive')
        {
          this.noMoreArticles = true;
        }
      }
    )
  }

  onScroll(): void {
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.scrollHeight;
    const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

    const atBottom = windowHeight + scrollPosition >= documentHeight;

    if (atBottom && !this.newArticlesLoading && !this.noMoreArticles) {
      this.newArticlesLoading = true
      this.loadMoreArticles();
    }
  }


  loadMoreArticles(): void {
    // Increment the current page
    this.page++;
    if(this.selectedFilter == 'active')
    {
      this.articlesService.getAllArticlesByDate(this.page).subscribe(
        (articles: Articles[]) => {
          // Append the new articles to the existing list
          this.articles = [...this.articles, ...articles];
          this.displayArticles = [...this.articles, ...articles];
          if(articles.length < this.articlesPerPage)
          {
            this.noMoreArticles = true;
          }
          this.newArticlesLoading = false
        },
        (error) => {
          this.snackBar.open(error, 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
          console.error('Error getting more articles:', error);
        }
      );
    }
    else {
      this.articlesService.getArchiveArticles(this.page).subscribe(
        (articles: Articles[]) => {
          // Append the new articles to the existing list
          this.deletedArticles = [...this.deletedArticles, ...articles];
          this.displayArticles = [...this.deletedArticles, ...articles];

          if(articles.length < this.articlesPerPage)
          {
            this.noMoreArticles = true;
          }
          this.newArticlesLoading = false
        },
        (error) => {
          this.snackBar.open(error, 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
          });
          console.error('Error getting more articles:', error);
        }
      );
    }


  }

  deleteArticle(article: Articles): void {
    if(this.selectedFilter === 'inactive'){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          title: 'Da li ste sigurni da želite izbrisati članak?',
          message: 'Članak će biti izbrisan, i više se neće moci povratiti.',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.articlesService.delete(article[ArticlesProperty.id]).subscribe(
            (response) => {
              // Remove the deleted article from the articles array
              this.articles = this.articles.filter(a => a !== article);
            },
            (error) => {
              // Handle error, e.g., display an error message
              console.error('Error archiving article:', error);
            }
          );
        }
      });
    }
    else{
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '300px',
        data: {
          title: 'Da li ste sigurni da želite izbrisati članak?',
          message: 'Članak će privremeno biti izbrisan, a nakon 30 dana se više neće moci povratiti.',
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.articlesService.archive(article[ArticlesProperty.id]).subscribe(
            (response) => {
              // Remove the deleted article from the articles array
              this.articles = this.articles.filter(a => a !== article);
            },
            (error) => {
              // Handle error, e.g., display an error message
              console.error('Error archiving article:', error);
            }
          );
        }
      });
    }

  }

  getActive(filter: string) {
    this.selectedFilter = filter
    this.displayArticles = this.articles
    if(this.articles.length < this.articlesPerPage)
    {

      this.noMoreArticles = true;
    }
  }
  getDelete(filter: string) {
    this.selectedFilter = filter
    this.displayArticles = this.deletedArticles
    if(this.deletedArticles.length < this.articlesPerPage)
    {

      this.noMoreArticles = true;
    }
  }

  protected readonly ArticlesService = ArticlesService;
  protected readonly ArticlesProperty = ArticlesProperty;
}
