import {ChangeDetectorRef, Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {ArticlesProperty} from "../../models/articles-property.enum";
import {CategoriesProperty} from "../../models/categories-property.enum";
import {Articles} from "../../models/articles.model";
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {CategoriesService} from "../../services/categories.services";
import {Categories} from "../../models/categories.model";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {SocialMediaService} from "../../services/social-meida.service";
import {ArticlesService} from "../../services/articles.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray
} from "@angular/cdk/drag-drop";
import {ImagesService} from "../../services/images.service";
import {ViewportRuler} from "@angular/cdk/overlay";
import {ArticlePreviewDialogComponent} from "./article-preview-dialog/article-preview-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {SurveysService} from "../../services/survey.service";
import {Surveys} from "../../models/surveys.model";
import {SurveysProperty} from "../../models/surveys-property.enum";
import {Chair} from "../../models/chair.model";
import {UserService} from "../../services/user.service";
import {ChairProperty} from "../../models/chair-property.enum";
import {TagsService} from "../../services/tags.services";
import {Tag} from "../../models/tag.model";
import {TagProperty} from "../../models/tag-property.enum";
import {Route} from "../../constants/route.constants";
import {ChairService} from "../../services/chair.service";
import {TagImagesDisplayDialogComponent} from "./tag-images-display-dialog/tag-images-display-dialog.component";
import {isPlatformBrowser, NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {SurveyComponent} from "../survey/survey.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ArticleUploadLoaderComponent} from "../../loaders/article-upload-loader/article-upload-loader.component";
import {ScheduleDialogComponent} from "./schedule-dialog/schedule-dialog.component";
import {timeSinceInMicros} from "@angular/compiler-cli/src/ngtsc/perf/src/clock";


@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    CdkDropList,
    CdkDrag,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    SurveyComponent,
    MatCheckboxModule,
    FormsModule,
    ArticleUploadLoaderComponent,
    CdkDragHandle
  ],
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit{
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList> | undefined;
  @ViewChild(CdkDropList) placeholder: CdkDropList | undefined;

  /*Form for the article*/
  public form!: FormGroup;

  /*User if it exsists*/
  chair: Chair | undefined;

  /*Article object for the article from form*/
  @Input()
  article!: Articles;
  /*Articles for preview*/
  public highlight: Articles[] = [];

  /*MEDIA*/

  /*Data for form inputs*/
  public categories: Categories[] = [];
  public surveys: Surveys[] = [];
  public allTags: Tag[] = [];
  public selectedTags: Tag[] = [];
  public receivedTagsControl: Tag[] = [];
  public displayNameOptions: string[] = [];

  /*Media input*/
  imageLinks: any[] = [];
  currentIndex = 0;
  public target: CdkDropList | null;
  public source: CdkDropList | null;

// Initialize a FormControl
  newLinkControl: FormControl = new FormControl('', Validators.required);

  isUploading: boolean = false
  isSuccess: boolean = false

  selectedFiles?: FileList;
  /*Still don't know what this is for, commented part in function*/
  fileInfos?: Observable<any>;

  /*If the user is editing*/
  private isEditing: boolean = false;

  /*Tags Search*/
  userInput = '';


  embedSafeSrc: SafeResourceUrl[] = [];


  public articlesProperty = ArticlesProperty;
  public categoriesProperty = CategoriesProperty;
  public surveysProperty = SurveysProperty;
  public chairProperty = ChairProperty;

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private formBuilder: FormBuilder,
    private articlesService: ArticlesService,
    private categoriesService: CategoriesService,
    private userService: UserService,
    private chairService: ChairService,
    private surveysService: SurveysService,
    private imagesService: ImagesService,
    private tagsService: TagsService,
    private socialMediaService: SocialMediaService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private viewportRuler: ViewportRuler,
    private router: Router,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
  ) {
    this.target = null;
    this.source = null;
  }

  /*SAFE HTML CODE AND URLS*/
  embedSafe: any[] = [];

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.chairService.getChair().subscribe(chair => {
        this.chair = chair
      });

      /*Check if the user is editing the exsisting article?*/
      const urlSegments = this.activatedRoute.snapshot.url;
      if (urlSegments.length >= 2) {
        // Access the second-to-last segment
        this.isEditing = urlSegments[urlSegments.length - 2].path === Route.EDIT;
      } else {
        // Handle the case where there are not enough segments
        this.isEditing = false; // or set it to some default value
      }

      if (this.isEditing) {
        /*Get the data of the article*/
        // @ts-ignore
        this.imageLinks = this.article[ArticlesProperty.image_list]?.map(item => item);
        this.tagsService.getActiveTags().subscribe(tags => {
          this.allTags = tags;
          this.receivedTagsControl = tags
          if (this.article) {
            // Extract tag names from the article
            const articleTagNames = this.article?.tags?.map(tag => tag.tag);
            // Filter tags that are present in the article
            const selectedTags = this.allTags.filter(tag => articleTagNames?.includes(tag.tag));

            // Remove selectedTags from allTags
            this.allTags = this.allTags.filter(tag => !articleTagNames?.includes(tag.tag));

            // Assign selectedTags to a separate property, if needed
            this.selectedTags = selectedTags;
          }
        });
        this.buildForm()
        this.sanitizeEmbed()
      }
      else {
        this.tagsService.getActiveTags().subscribe(tags => {
          this.allTags = tags;
          this.receivedTagsControl = tags
        });
        this.buildForm()
      }

      /*Retrieve the user data*/
      //this.chair = this.userService.getChair()

      // Two fixed options and two dynamic options
      if (this.chair) {
        if(this.chair[ChairProperty.accessAllSignatures]){
          this.chairService.getSignatures().subscribe(
            (signatures: any) => {
              this.displayNameOptions = [
                'Hayat',
                'Preuzeto',
              ];
              signatures.forEach((signature: { name: string; initials: string; }) => {
                this.displayNameOptions.push(signature.name, signature.initials);
              });
              /*const signatureNames = signatures.map((signature: { name: string; }) => signature.name);
              const signatureInitials = signatures.map((signature: { initials: string; }) => signature.initials);
              this.displayNameOptions = [
                'Hayat',
                'Preuzeto',
                ...signatureNames,
                ...signatureInitials,
              ];*/
              const indexName = this.displayNameOptions.indexOf(this.chair?.[ChairProperty.name]!);
              if (indexName > -1) {
                // Remove the chairName from its current position
                this.displayNameOptions.splice(indexName, 1);
                // Add the chairName to the top
                this.displayNameOptions.unshift(this.chair?.[ChairProperty.name]!);
                 const indexInitial = this.displayNameOptions.indexOf(this.chair?.[ChairProperty.initials]!);
                if (indexInitial > -1) {
                  this.displayNameOptions.splice(indexInitial, 1);
                  this.displayNameOptions.unshift(this.chair?.[ChairProperty.initials]!);
                }
              }
            },
            (error) => {
              console.error('Error fetching signatures:', error);
            }
          );
        }
        else {
          this.displayNameOptions = [
            this.chair[ChairProperty.name],
            'Hayat',
            'Preuzeto',
          ];
        }

      }
      /*Retrieve the categories data*/
      this.categoriesService.getCategories().subscribe(
        (response) => {
          this.categories = response;
        },
        (error) => {
          console.error('Failed to get channels:', error);
        }
      )
    }
  }

  get selectedPriority(): number | null {
    return this.form.get(ArticlesProperty.priority)?.value;
  }

  /*BUILDING FORM*/
  buildForm() {
    this.form = this.formBuilder.group({
      [ArticlesProperty.id]: [this.article?.[ArticlesProperty.id] || ''],
      [ArticlesProperty.title]: [this.article?.[ArticlesProperty.title] || '', Validators.required],
      [ArticlesProperty.subtitle]: [this.article?.[ArticlesProperty.subtitle] || '', Validators.required],
      [ArticlesProperty.text]: [this.article?.[ArticlesProperty.text] || ''],
      textEditors: this.formBuilder.array([]),
      displayName: [this.article?.[ArticlesProperty.display_name] || '', Validators.required],
      [ArticlesProperty.category]: [this.article?.[ArticlesProperty.category] || '', Validators.required],
      [ArticlesProperty.image_list]: [this.article?.[ArticlesProperty.image_list] || ''],
      [ArticlesProperty.priority]: [this.article?.[ArticlesProperty.priority] || null],
      [ArticlesProperty.editor]: [this.chair],
      [ArticlesProperty.tags]: [{ value: this.article?.[ArticlesProperty.tags] || '' }],
      notification: false,
    });

    if(this.isEditing && this.article){
      // @ts-ignore
      this.article.text.forEach((editor) => {
        if (editor.type === 'text') {
          // For 'text' type, create a text editor form group
          const textEditorForm = this.formBuilder.group({
            text: [editor.html || '', Validators.required],
          });
          this.getText.push(textEditorForm);

        } else if (editor.regular) {
          // Similar logic for other editor types
          const regularForm = this.formBuilder.group({
            regular: [editor.regular || '', Validators.required],
          });
          this.getText.push(regularForm);

        } else if (editor.bold) {
          // Similar logic for other editor types
          const boldForm = this.formBuilder.group({
            bold: [editor.bold || '', Validators.required],
          });
          this.getText.push(boldForm);

        } else if (editor.italic) {
          // Similar logic for other editor types
          const italicForm = this.formBuilder.group({
            italic: [editor.italic || '', Validators.required],
          });
          this.getText.push(italicForm);
        } else if (editor.link) {
          // Similar logic for other editor types
          const linkForm = this.formBuilder.group({
            link: [editor.link || '', Validators.required],
            linkText: [editor.linkText || '', Validators.required],
          });
          this.getText.push(linkForm);
        } else if (editor.instagram) {
          // Similar logic for other editor types
          const instagramForm = this.formBuilder.group({
            instagram: [editor.instagram || '', Validators.required],
          });
          this.getText.push(instagramForm);
        }  else if (editor.youtube) {
          // Similar logic for other editor types
          const instagramForm = this.formBuilder.group({
            youtube: [editor.youtube || '', Validators.required],
          });
          this.getText.push(instagramForm);
        } else if (editor.facebook) {
          // Create Facebook editor form group
          const facebookForm = this.formBuilder.group({
            facebook: [editor.facebook || '', Validators.required],
          });
          this.getText.push(facebookForm);
        } else if (editor.tiktok) {
          // Create Facebook editor form group
          const tiktokForm = this.formBuilder.group({
            tiktok: [editor.tiktok || '', Validators.required],
          });
          this.getText.push(tiktokForm);
        } else if (editor.x) {
          // Create Facebook editor form group
          const xForm = this.formBuilder.group({
            x: [editor.x || '', Validators.required],
          });
          this.getText.push(xForm);
        } else if (editor.survey) {
          // Create Facebook editor form group
          const surveyForm = this.formBuilder.group({
            survey: [editor.html || '', Validators.required],
            embed: [editor.embed._id],
          });

          // Additional logic for fetching surveys if needed
          this.surveysService.getSurveys().subscribe(
            (response) => {
              this.surveys = response;
            },
            (error) => {
              console.error('Failed to get surveys:', error);
            }
          );
          this.getText.push(surveyForm);
        }
        else if (editor.image) {
          // Create Facebook editor form group
          const imageForm = this.formBuilder.group({
            image: [editor.image || '', Validators.required],
          });
          this.getText.push(imageForm);
        }
        // Add other editor types as needed
      });
    }
  }


  /*MEDIA INPUT FUNCTIONS*/
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.imageLinks, event.previousIndex, event.currentIndex);
  }

  dropContent(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getText.controls, event.previousIndex, event.currentIndex);
  }
  next() {
    this.currentIndex = (this.currentIndex + 1) % this.imageLinks.length;
  }
  prev() {
    this.currentIndex = (this.currentIndex - 1 + this.imageLinks.length) % this.imageLinks.length;
  }
  insertLink(link: string) {
    // Check if the newLink ends with a valid image or video extension
    if (link.match(/\.(mp4|webp|jpg|png|mov|jpeg)$/i)) {
      const imageInfo = {
        url: link,
        tag: '',
      };
      this.imageLinks.push(imageInfo);
      this.newLinkControl.setValue('');
    } else {
      // Display an error or provide feedback to the user
      console.log('Invalid link. Please provide a valid image or video link.');
    }
  }
  selectFiles(event: Event): void {
    // @ts-ignore
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {

        if (this.selectedFiles[i].size > 5 * 1024 * 1024){
          console.error(`File ${this.selectedFiles[i].name} exceeds the maximum allowed size.`);
          this.snackBar.open(`File ${this.selectedFiles[i].name} exceeds the maximum allowed size.`, 'Close', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['error-snackbar']
          });
        }
        else {
          this.upload(i, this.selectedFiles[i]);
        }
      }
    }
  }
  uploadFiles(): void {
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].size)
          this.upload(i, this.selectedFiles[i]);
      }
    }
  }
  upload(idx: number, file: File): void {
    if (file) {

      this.imagesService.upload(file, 'media').subscribe(
        (response) => {
          const imageInfo = {
            url: response.imageUrl,
            tag: '',
          };
          this.imageLinks.push(imageInfo);
        },
        (error) => {
          // Handle error, e.g., display an error message
          console.error('Error creating article:', error);
        });
    }
  }
  public removeImage(link: string): void {
    this.imageLinks.forEach((item, index) => {
      if (link === item.url) this.imageLinks.splice(index, 1);
    });
    if(this.currentIndex === this.imageLinks.length && this.currentIndex != 0){
      this.currentIndex = this.currentIndex - 1;
    }
  }
  /*MEDIA INPUT FUNCTIONS*/

  /*TAGS INPUT FUNCTIONS*/
  filterTags(): Tag[] {
    const filteredTags = this.allTags
      .filter(tag => tag[TagProperty.tag].toLowerCase().includes(this.userInput.toLowerCase()))
      .slice(0, 20);
    return filteredTags;
  }

  addTag(tag: Tag): void {
    this.selectedTags.push(tag);
    this.allTags = this.allTags.filter(t => t !== tag);
  }
  removeTag(selectedTag: Tag): void {
    this.selectedTags = this.selectedTags.filter(tag => tag !== selectedTag);
    this.allTags.push(selectedTag);
  }
  onEnter(): void {
    const trimmedInput = this.userInput.trim().toUpperCase();
    if (!trimmedInput) {
      return; // Do nothing if the input is empty or contains only whitespace
    }
    const existingTag = this.receivedTagsControl.find(tag => tag.tag === trimmedInput);

    if (this.selectedTags.find(tag => tag.tag === trimmedInput)) {
      this.snackBar.open("Tag je već odabran", 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['error-snackbar']
      });
    } else if(existingTag){
      this.addTag(existingTag);
    }
    else {
      // If the tag doesn't exist, add it to the database and then add it to the selected tags
      const newTag: Tag = { tag: trimmedInput, active: true, imagesURL: [] };
      this.tagsService.create(newTag).subscribe(
        addedTag => {
          this.allTags.push(addedTag);
          this.addTag(addedTag);
        },
        error => {
          console.error('Failed to add new tag:', error);
          // Handle the error (e.g., show a message to the user)
        }
      );
    }
    this.userInput = ''

  }
  /*TAGS INPUT FUNCTIONS*/







  disableCheckboxes(selectedCheckbox: string) {
    const checkboxes = [
      ArticlesProperty.videoPost,
      ArticlesProperty.textPost,
      ArticlesProperty.photoPost
    ];
    const control = this.form.get(selectedCheckbox);
    if(control?.value){
      checkboxes.forEach(checkbox => {
        if (checkbox !== selectedCheckbox) {
          this.form.get(checkbox)?.disable();
        } else {
          this.form.get(checkbox)?.enable();
        }
      });
    }
    else{
      checkboxes.forEach(checkbox => {
        this.form.get(checkbox)?.enable();
      });
    }
  }

  /*TEXT RELATED FUNCTIONS*/
  get getText() {
    return this.form.controls["textEditors"] as FormArray;
  }

  addRegularEditor() {
    const regularEditorForm = this.formBuilder.group({
      regular: ['', Validators.required],
    });
    this.getText.push(regularEditorForm);
  }
  addBoldEditor() {
    const boldEditorForm = this.formBuilder.group({
      bold: ['', Validators.required],
    });
    this.getText.push(boldEditorForm);
  }
  addItalicEditor() {
    const italicEditorForm = this.formBuilder.group({
      italic: ['', Validators.required],
    });
    this.getText.push(italicEditorForm);
  }
  addLinkEditor() {
    const linkEditorForm = this.formBuilder.group({
      link: ['', Validators.required],
      linkText: ['', Validators.required],
    });
    this.getText.push(linkEditorForm);
  }

  addInstagram() {
    const instagramForm = this.formBuilder.group({
      instagram: ['', Validators.required],
    });

    this.getText.push(instagramForm);
  }
  addFacebook() {
    const facebookForm = this.formBuilder.group({
      facebook: ['', Validators.required],
    });

    this.getText.push(facebookForm);
  }
  addX() {
    const facebookForm = this.formBuilder.group({
      x: ['', Validators.required],
    });

    this.getText.push(facebookForm);
  }
  addTiktok() {
    const tiktokForm = this.formBuilder.group({
      tiktok: ['', Validators.required],
    });

    this.getText.push(tiktokForm);
  }
  addYoutube() {
    const facebookForm = this.formBuilder.group({
      youtube: ['', Validators.required],
    });

    this.getText.push(facebookForm);
  }
  addSurvey() {
    const surveyForm = this.formBuilder.group({
      survey: ['', Validators.required],
    });

    this.surveysService.getSurveys().subscribe(
      (response) => {
        this.surveys = response;
      },
      (error) => {
        console.error('Failed to get channels:', error);
      })

    this.getText.push(surveyForm);
  }
  addImage() {
    const imageForm = this.formBuilder.group({
      image: ['', Validators.required],
    });

    this.getText.push(imageForm);
  }
  deleteForm(index: number) {
    this.getText.removeAt(index);
  }

  isRegularEditor(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('regular') !== null;
  }
  isBoldEditor(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('bold') !== null;
  }
  isItalicEditor(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('italic') !== null;
  }

  isInstagram(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('instagram') !== null;
  }

  isFacebook(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('facebook') !== null;
  }

  isX(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('x') !== null;
  }

  isTiktok(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('tiktok') !== null;
  }
  isYoutube(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('youtube') !== null;
  }
  isSurvey(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('survey') !== null;
  }
  isImage(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('image') !== null;
  }
  isLink(control: AbstractControl): boolean {
    return control instanceof FormGroup && control.get('link') !== null;
  }
  /*TEXT RELATED FUNCTIONS*/


  //update posts
  updateIframeURL(control: AbstractControl): SafeResourceUrl | null {
    // @ts-ignore
    const url = control.get('instagram').value;
    if (url) {
      const iframe = "<iframe loading=\"lazy\" width=\"320\" frameborder=\"0\" data-instgrm-payload-id=\"instagram-media-payload-0\" scrolling=\"no\" allowtransparency=\"true\" allowfullscreen=\"true\" style=\"margin: auto !important; padding: 1px; display:flex; border-radius: 5px;background: #d8d8d8; box-shadow: 0 0 20px 0 #d8d8d8;\" src=\"" + url + "/embed/captioned\" height=\"678px\"></iframe>";
      /*const iframe = "<blockquote class=\"instagram-media\" data-instgrm-permalink=\"" + url + "\" data-instgrm-version=\"14\">" +
        "<a href=\"" + url + "\"></a>\n" +
        "</blockquote>" +
        "  <script async src=\"//www.instagram.com/embed.js\"></script>";
      */
      return this.sanitizer.bypassSecurityTrustHtml(iframe);
      // Sanitize the URL to bypass security checks
      //return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return null; // Return null if the input is empty
    }
  }

  updateXEmbed(control: AbstractControl): SafeResourceUrl | null {
    // @ts-ignore
    const url = control.get('x').value;
    if (url) {
      const iframe = "<blockquote class=\"twitter-tweet\">\n" +
        "                <a href=\"" + url + "\"></a>\n" +
        "              </blockquote>" +
        "<script async src=\"https://platform.twitter.com/widgets.js\" charset=\"utf-8\"></script>";
      return this.sanitizer.bypassSecurityTrustHtml(iframe);
      // Sanitize the URL to bypass security checks
      //return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return null; // Return null if the input is empty
    }
  }


  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const articleData: Articles = {
        ...this.form.value,
        [ArticlesProperty.image_list]: this.imageLinks.map((link, index) => ({ url: link.url, order: index, tag: link.tag })),
        [ArticlesProperty.editor]: this.chair,
        [ArticlesProperty.tags]: this.selectedTags
      };

      const isPriorityNotNull = this.form.get([ArticlesProperty.priority])?.value !== null;
      if (isPriorityNotNull) {
        this.articlesService.getArticleHighlight().subscribe(
          (response) => {
            this.highlight = response;

            const dialogRef = this.dialog.open(ArticlePreviewDialogComponent, {
              maxWidth: '90vw',
              data: { articles: this.highlight, article: articleData },
            });

            dialogRef.afterClosed().subscribe(result => {
              if (result === true) {
                this.handleArticleSubmission(articleData);
              }
            });
          },
          (error) => {
            console.error('Failed to get channels:', error);
          }
        );
      } else {
        this.handleArticleSubmission(articleData);
      }
    } else {
      const titleControl = this.form.get('title');
      const subtitleControl = this.form.get('subtitle');
      const categoryControl = this.form.get('category');
      const signatureControl = this.form.get('displayName');


      if(titleControl?.valid == false){
        this.snackBar.open("Nedostaje vam naslov", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      }
      else if(subtitleControl?.value.length < 60)
      {
        this.snackBar.open("Podnaslov je previse kratak", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      }
      else if(categoryControl?.valid == false)
      {
        this.snackBar.open("Niste izabrali kategoriju", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      }
      else if(signatureControl?.valid == false)
      {
        this.snackBar.open("Potpis (signature) nije izabran", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      }

      /*Control of validation*/
      /*Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        console.log(`${field}: ${control?.valid}`);
      });*/
    }
  }
  onSchedule() {
    this.form.markAllAsTouched();

    if (this.form.valid) {

      const dialogReff = this.dialog.open(ScheduleDialogComponent, {
        width: '300px', // Adjust the width as per your design
      });

      dialogReff.afterClosed().subscribe((result: any) => {
        if (result) {
          const articleData: Articles = {
            ...this.form.value,
            [ArticlesProperty.image_list]: this.imageLinks.map((link, index) => ({ url: link.url, order: index, tag: link.tag })),
            [ArticlesProperty.editor]: this.chair,
            [ArticlesProperty.tags]: this.selectedTags,
            schedule_time: result // Assign the entered date and time to schedule_time
          };

          const isPriorityNotNull = this.form.get([ArticlesProperty.priority])?.value !== null;

          if (isPriorityNotNull) {
            this.articlesService.getArticleHighlight().subscribe(
              (response) => {
                this.highlight = response;

                const dialogRef = this.dialog.open(ArticlePreviewDialogComponent, {
                  maxWidth: '90vw',
                  data: { articles: this.highlight, article: articleData },
                });

                dialogRef.afterClosed().subscribe(result => {
                  if (result === true) {
                    this.handleArticleSchedule(articleData);
                  }
                });
              },
              (error) => {
                console.error('Failed to get channels:', error);
              }
            );
          } else {
            this.handleArticleSchedule(articleData);
          }
        }
      })

    } else {
      const titleControl = this.form.get('title');
      const subtitleControl = this.form.get('subtitle');
      const categoryControl = this.form.get('category');


      if(titleControl?.valid == false){
        this.snackBar.open("Nedostaje vam naslov", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      }
      else if(subtitleControl?.value.length < 60)
      {
        this.snackBar.open("Podnaslov je previse kratak", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      }
      else if(categoryControl?.valid == false)
      {
        this.snackBar.open("Niste izabrali kategoriju", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      }
    }
  }

  private handleArticleSubmission(articleData: Articles): void {
    const submissionObservable = this.isEditing ?
      this.articlesService.update(articleData[ArticlesProperty.id]!, articleData) :
      this.articlesService.create(articleData);

    this.isUploading = true;

    submissionObservable.subscribe(
      () => {
        this.isUploading = false;
        this.isSuccess = true;
        setTimeout(() => {
          this.isSuccess = false;
        }, 2000);

        if (this.isEditing) {
          this.router.navigate([Route.ARTICLE + Route.SEPARATOR + this.article[ArticlesProperty.url_title] + Route.SEPARATOR + this.article[ArticlesProperty.id]]);
        }
      },
      (error) => {
        console.error('Failed to create/update article:', error);
      }
    );
  }
  private handleArticleSchedule(articleData: Articles): void {
    const submissionObservable = this.articlesService.schedule(articleData);

    this.isUploading = true;

    submissionObservable.subscribe(
      () => {
        this.isUploading = false;
        this.isSuccess = true;
        setTimeout(() => {
          this.isSuccess = false;
        }, 2000);

        if (this.isEditing) {
          // @ts-ignore
          this.router.navigate([Route.ARTICLE + Route.SEPARATOR + this.article[ArticlesProperty.url_title] + Route.SEPARATOR + this.article[ArticlesProperty.id]]);
        }
      },
      (error) => {
        console.error('Failed to create/update article:', error);
      }
    );
  }

  compareFn(user1: Categories, user2: Categories) {
    return user1 && user2 ? user1[CategoriesProperty.id] === user2[CategoriesProperty.id] : user1 === user2;
  }
  compareSFn(user1: Surveys, user2: Surveys) {
    return user1 && user2 ? user1[SurveysProperty.id] === user2[SurveysProperty.id] : user1 === user2;
  }

  compareSignatureFunction(article1: Articles, article2: Articles) {
    return article1 && article2 ? article1[ArticlesProperty.display_name] === article2[ArticlesProperty.display_name] : article1 === article2;
  }

  onKeyDown(event: Event, controlName: string, index: number): void {
    if (event instanceof KeyboardEvent) {
      if (event.key === 'Enter') {
        const control = this.form.get(`textEditors.${index}.${controlName}`);
        if (control) {
          control.setValue(control.value + '\n');
          event.preventDefault();
        }
      }
    }
  }

  public sanitizeEmbed(){
    let index = 0; // Initialize index variable
    for (const item of this.article[ArticlesProperty.text]) {
      if(item.facebook){
        this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(item.facebook);
      }
      if(item.tiktok){
        this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(item.tiktok);
      }
      if(item.x){
        this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(item.x);
      }
      if (item.youtube) {
        this.embedSafe[index] = this.sanitizer.bypassSecurityTrustResourceUrl(item.youtube);
      }
      if(item.instagram){
        this.embedSafe[index] = this.sanitizer.bypassSecurityTrustResourceUrl(item.instagram);
      }
      index++
    }
    this.cdRef.detectChanges();
  }

  fetchInstagramEmbed(control: AbstractControl, index: number) {
    const instagramUrl = control.get('instagram')?.value;
    const instagramControl = control.get('instagram');

    if (instagramUrl) {
      const instagramRegexp = new RegExp('[a-zA-Z]+://([a-zA-Z]+(\\.[a-zA-Z]+)+)/([a-z]+)/[A-Za-z0-9-_]+');
      const socialLink = instagramUrl.match(instagramRegexp)[0] + "/embed/captioned";
      const iframe = "<iframe loading=\"lazy\" width=\"320\" frameborder=\"0\" data-instgrm-payload-id=\"instagram-media-payload-0\" scrolling=\"no\" allowtransparency=\"true\" allowfullscreen=\"true\" style=\"padding: 2px; margin: 10px auto !important; display:flex; box-shadow: 0 0 9px 0; border: 1px solid #d8d8d8; border-radius: 10px; min-width: 275px; max-width: 320px; aspect-ratio: 9/16; width: 100%; height: auto\" src=\"" + socialLink + "\" height=\"817\"></iframe>";
      this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(iframe);
      instagramControl?.setValue(socialLink);
    }
  }
  fetchTiktokEmbed(control: AbstractControl, index: number): any {
    const tiktokUrl = control.get('tiktok')?.value;
    const tiktokControl = control.get('tiktok')
    if (tiktokUrl) {
      const socialLink = "https://www.tiktok.com/embed/v2/" + tiktokUrl.substring(tiktokUrl.lastIndexOf('/') + 1, tiktokUrl.length);
      const iframe = "<iframe loading=\"lazy\" scrolling=\"no\" sandbox=\"allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation allow-same-origin\" class=\"tiktok-iframe ng-star-inserted\" style=\"height: 739px; width: 324px; margin: 10px auto; border-radius: 9px; border: solid 1px #d7d7d7; display: block; visibility: unset; max-height: 739px;\" src=\"" + socialLink + "\"></iframe>";
      this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(iframe);
      tiktokControl?.setValue(socialLink);
    }
  }
  fetchYoutubeEmbed(control: AbstractControl, index: number): any {
    const youtubeUrl = control.get('youtube')?.value;
    if (youtubeUrl) {
      let videoId = this.extractYoutubeVideoId(youtubeUrl);
      if (videoId) {
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        this.embedSafe[index] = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
        control.get('youtube')?.setValue(embedUrl);
      }
    }
  }
  fetchFacebookEmbed(control: AbstractControl, index: number) {
    const facebookIframe = control.get('facebook')?.value;
    const facebookControl = control.get('facebook')
    if (facebookIframe) {
      this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(facebookIframe);
      facebookControl?.setValue(facebookIframe);
    }
  }
  fetchXEmbed(control: AbstractControl, index: number): any {
    const xIframe = control.get('x')?.value;
    const xControl = control.get('x');
    if (xIframe) {
      this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(xIframe);
      xControl?.setValue(xIframe);
    }
  }

  extractYoutubeVideoId(url: string): string | null {
    const regExp = /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  }
  private isUrlAlreadyPresent(url: string): boolean {
    return this.embedSafeSrc.some(embedUrl => {
      if (typeof embedUrl === 'string') {
        // @ts-ignore
        return embedUrl.changingThisBreaksApplicationSecurity === url;
      } else {
        // @ts-ignore
        return (embedUrl as SafeResourceUrl).changingThisBreaksApplicationSecurity === url;
      }
    });
  }

  fetchImageEmbed(control: AbstractControl): any {
    this.cdRef.detectChanges()
  }

  /*SURVEY RELATED FUNCITONS*/
  updateEmbedField(selectedSurveyId: string, control: AbstractControl) {
    // @ts-ignore
    control.get('survey').setValue('');
    setTimeout(() => {
      // @ts-ignore
      control.get('survey').setValue(selectedSurveyId);
    }, 0);
  }
  /*SURVEY RELATED FUNCITONS*/



  copyCode(link: string) {
    navigator.clipboard.writeText(link).then(() => {
      this.snackBar.open('Link copied to clipboard', 'Dismiss', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
    }, () => {
      this.snackBar.open('Unable to copy link to clipboard', 'Dismiss', {
        duration: 3000,
        panelClass: ['snackbar-error']
      });
    });
  }

  public isVideo(end: string | undefined | null): boolean {
    // Check if 'end' is defined and ends with 'mp4'
    return typeof end === 'string' && end.endsWith('mp4');
  }

  openImageSelector() {
    const dialogRef = this.dialog.open(TagImagesDisplayDialogComponent, {
      width: '90vw',
      data: {
        tags: this.allTags
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.imageLinks.push(...result)
        // Handle selected images
      }
    });
  }




  isEditingFunction(): boolean{
    return this.isEditing
  }

  protected readonly ArticlesProperty = ArticlesProperty;
  protected readonly ChairProperty = ChairProperty;
}
