import {ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SurveysService} from "../../../../services/survey.service";
import {UserService} from "../../../../services/user.service";
import {ChairService} from "../../../../services/chair.service";
import {ArticlesProperty} from "../../../../models/articles-property.enum";
import {Chair} from "../../../../models/chair.model";
import {ChairProperty} from "../../../../models/chair-property.enum";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";

@Component({
  selector: 'app-create-chair-dialog',
  templateUrl: './create-chair-dialog.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule
  ],
  styleUrls: ['./create-chair-dialog.component.css']
})
export class CreateChairDialogComponent implements OnInit{
  chairForm: FormGroup;
  @Output() editorCreated = new EventEmitter<Chair>();

  constructor(
    public dialogRef: MatDialogRef<CreateChairDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public editor: Chair,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private chairService: ChairService,
    private cdRef: ChangeDetectorRef,

  ) {
    this.chairForm = this.formBuilder.group({
      _id: [this.editor?.[ChairProperty.id] || ''],
      email: [this.editor?.[ChairProperty.email] || '', Validators.required],
      password: ['', Validators.required],
      name: [this.editor?.[ChairProperty.name] || '', Validators.required],
      initials: [this.editor?.[ChairProperty.initials] || '', Validators.required],
      isAdmin: [this.editor?.[ChairProperty.isAdmin] || false],
      isEditor: [this.editor?.[ChairProperty.isEditor] || false],
      permissionArticles: [this.editor?.[ChairProperty.permissionArticles] || false],
      permissionCategories: [this.editor?.[ChairProperty.permissionCategories] || false],
      permissionTags: [this.editor?.[ChairProperty.permissionTags] || false],
      permissionShows: [this.editor?.[ChairProperty.permissionShows] || false],
      permissionSurveys: [this.editor?.[ChairProperty.permissionSurveys] || false],
      accessAllSignatures: [this.editor?.[ChairProperty.accessAllSignatures] || false],
      sendNotification: [this.editor?.[ChairProperty.sendNotification] || false],
      permissionCreateChairs: [this.editor?.[ChairProperty.permissionCreateChairs] || false],

    });

    this.setupConditionalEnabling()
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if(this.editor){
      this.chairService.updateChair(this.chairForm.value).subscribe(
        (result:any) => {
          this.dialogRef.close({ editor: this.chairForm.value, isUpdated: true }); // Pass result and flag to close()
        },
        (error) => {
          console.error('Error updating user:', error);
          // Handle the error as needed
        }
      )
    }
    if (this.chairForm.valid) {
      const formData = this.chairForm.value;
      // Send the form data to the backend
      this.chairService.create(formData).subscribe(
        (result:any) => {
          this.editorCreated.emit(result.chair); // Emit the event with the created editor
          this.dialogRef.close({ editor: formData, isUpdated: false }); // Pass result and flag to close()

        },
        (error) => {
          console.error('Error creating user:', error);
          // Handle the error as needed
        }
      );
    }
  }
  showPassword = false;

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
    const passwordInput = this.chairForm.get('password');

    if (passwordInput instanceof FormControl) {
      const inputElement = document.getElementById('password') as HTMLInputElement;

      if (inputElement) {
        inputElement.type = this.showPassword ? 'text' : 'password';
      }
    }
  }

  setupConditionalEnabling() {
    if(!this.editor){
      this.chairForm.get('permissionArticles')?.disable()
      this.chairForm.get('permissionTags')?.disable()
      this.chairForm.get('permissionShows')?.disable()
      this.chairForm.get('permissionSurveys')?.disable()
      this.chairForm.get('accessAllSignatures')?.disable()
    }
  }


}
