<div class="questions">
  @if(!messagesLoading){
    <div class="chat" #chatContainer>
      <!--@if(!hasDeadlinePassed){
        @if(timeRemaining){
          <div class="timer">
            <div class="blue-wrap">
              <p *ngIf="timeRemaining.days > 0">
                Preostalo vrijeme: {{formatNumber(timeRemaining.days)}}:{{formatNumber(timeRemaining.hours)}}:{{formatNumber(timeRemaining.minutes)}}:{{formatNumber(timeRemaining.seconds)}}
              </p>
              <p *ngIf="timeRemaining.days === 0 && (timeRemaining.hours > 0 || timeRemaining.minutes > 0 || timeRemaining.seconds > 0)">
                Preostalo vrijeme: {{formatNumber(timeRemaining.hours)}}:{{formatNumber(timeRemaining.minutes)}}:{{formatNumber(timeRemaining.seconds)}}
              </p>
            </div>
          </div>
        } @else {
          <div class="blue-wrap">
            <span class="loader"></span>
          </div>
        }
      }-->
      <!--<div class="chat" *ngIf="!messagesLoading; else messagesSpinner" #chatContainer (scroll)="onScroll()">-->
      @if(questions.length > 0){
        <div>
          <div *ngFor="let message of questions; let i = index" class="message" [attr.data-message-id]="message._id">
            @if (message.user.userId === user?.id || message.approved){
              <div class="messages-right">
                @if(message.user.userId === user?.id){
                  <div class="delete-button">
                    <button (click)="deleteUserMessage(message)">X</button>
                  </div>
                } @else if (this.guest && !message.answer){
                  @if(!questionToAnswer){
                    <button class="edit-button" (click)="addAnswer(message)">
                      <!--<i class="fa fa-pencil"></i>-->Odgovorite
                    </button>
                  } @else if(questionToAnswer.question === message.question){
                    <button class="edit-button" (click)="addAnswer(null)">
                      <!--<i class="fa fa-pencil"></i>-->Cancel
                    </button>
                  }
                }

                <!--<div [ngClass]="{'messages-right': message.user.username == getUserUsername(), 'messages-left': message.user.username != getUserUsername()}">-->
                <div class="user-profile">
                  <img *ngIf="message.user.imageURL; else noProfilePhoto" [src]="message.user.imageURL" alt="Profile Picture" class="profile-image">
                  <ng-template #noProfilePhoto>
                    <mat-icon class="account-icon">account_circle</mat-icon>
                  </ng-template>
                  <div class="message-username" [ngClass]="{'user-question': message.user.userId === user?.id}" (contextmenu)="onMessageContextMenu($event, message)">
                    <span class="user-username">{{ message.user.name }}</span>
                    <span class="message-text">
                    {{ message.question }}
                  </span>
                    <span class="message-details">
                    {{ formattedDate(message.date_time) }}
                  </span>
                  </div>
                </div>
              </div>
              @if(message.answer){
                <div class="messages-left">
                  <img *ngIf="message.guest.profilePicture; else noProfilePhoto" [src]="message.guest.profilePicture" alt="Profile Picture" class="profile-image">
                  <div class="message-regular" (contextmenu)="onMessageContextMenu($event, message)">
                    <span class="user-username">{{ message.guest.name }}</span>
                    <span class="message-text">
                  {{ message.answer }}
                  </span>
                  </div>
                  @if(message.guest.guestId === guest?.[GuestProperty.id]){
                    <button class="edit-button" (click)="addAnswer(message)">
                      <!--<i class="fa fa-pencil"></i>-->Edit
                    </button>
                    <button class="edit-button" (click)="deleteAnswer(message)">
                      <!--<i class="fa fa-pencil"></i>-->Delete
                    </button>
                  }
                </div>

              }
            }
          </div>
        </div>
      } @else{
        <div class="no-chat">
          Nema postavljenih pitanja
        </div>
      }
    </div>
  } @else {
    <div class="loading-spinner">
      <div class="spinner"></div>
    </div>
  }

    <!-- Context menu container -->
    <!--<div class="context-menu" *ngIf="showContextMenu" [style.left.px]="contextMenuX" [style.top.px]="contextMenuY">
      <ul>
        <li (click)="editMessage(selectedMessage)">Edit</li>
        <li (click)="deleteMessage(selectedMessage)">Delete</li>
        &lt;!&ndash; Add more context menu options as needed &ndash;&gt;
      </ul>
    </div>-->



  @if(!alreadyAsked && !hasDeadlinePassed && user){
    <div class="message-input">
      <input type="text" [(ngModel)]="newQuestion" placeholder="Možete postaviti samo jedno pitanje">
      <button (click)="sendMessage()">Send</button>
    </div>
  }
  @else if (user === undefined && guest === undefined && !hasDeadlinePassed){
    <div class="login-prompt">
      <p>Prijava je potrebna ukoliko želite postaviti pitanje</p>
      <button (click)="redirectToLogin()">Login</button>
    </div>
  }
  @else if(guest !== undefined && isGuestOfTheEpisode){
    <div class="message-input">
      <input type="text" [(ngModel)]="answer" placeholder="Odgovorite na pitanje" [disabled]="isInputDisabled">
      <button (click)="sendAnswer()" [disabled]="isInputDisabled">Odgovori</button>
    </div>
  }


  <!--<div class="notification-bar">
    <h3>Notifications</h3>
    <ul>
      &lt;!&ndash;<li *ngFor="let notification of notifications">
        {{ notification }}
      </li>&ndash;&gt;
    </ul>
  </div>-->
</div>
